<template>
  <v-container class="publish-mobile-draft-dialog mb-8">
    <v-row>
      <v-col cols="12" class="publish-mobile-draft-dialog__content">
        <v-row no-gutters>
          <v-col cols="12" class="publish-mobile-draft-dialog__content--text">
            <span>{{ $trans("publish_mobile_draft_dialog_subtitle") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="blue"
              dark
              depressed
              class="publish-mobile-draft-dialog__content--button"
              @click="goToDrafts"
            >
              {{ $trans("publish_dialog_button") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "PublishMobileDraftDialog",
  mixins: [sharedActions],
  methods: {
    goToDrafts() {
      this.$router.push({ name: "tools", params: { tab: "app" } });
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-mobile-draft-dialog__content {
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
  background: var(--v-accent-base);
}

.publish-mobile-draft-dialog__content .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.publish-mobile-draft-dialog__content--text,
.publish-mobile-draft-dialog__content--domain {
  text-align: center;
  margin-bottom: 2rem;
}

.publish-mobile-draft-dialog__content--domain span:first-child {
  margin-right: 0.5rem;
}
</style>
